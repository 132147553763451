import { state } from "@angular/animations";
import { inject, Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { AddAssessment, FlagAssessment, FlagAssessmentUpdate, GetAssessment, GetAssessmentByWeeklySchedule, ListAssessmentUsers, ReleaseAllResults, ReleaseSingleResult, ResetSelectionFilter, SelectAssessmentUser, SetError, SetLoading, SetSelectionFilter } from "@tsin-core/actions/assessment.action";
import { AssessmentStateModel, AssessmentUser, LoadingAssessmentState } from "@tsin-core/models/assessment.model";
import { AssessmentService } from "@tsin-core/services/http/assessment.service";
import { catchError, of, tap } from "rxjs";


@State<AssessmentStateModel>({
  name: 'assessmentState',
  defaults: {
    loading: LoadingAssessmentState.loadingList,
    assessment: [],
    userList: [],
    selectedUser: null,
    selectedAsessmentFilter: null,
    error: null,
  }
})

@Injectable()
export class AssessmentState {
  assessmentService: AssessmentService = inject(AssessmentService);

  @Selector()
  static getAssessment(state: AssessmentStateModel) {
    return state.assessment;
  }

  @Selector()
  static getAssessmentUser(state: AssessmentStateModel) {
    return state.selectedUser;
  }


  @Selector()
  static getAssessmentUsers(state: AssessmentStateModel) {
    return state.userList;
  }

  @Selector()
  static getLoading(state: AssessmentStateModel) {
    return state.loading;
  }

  @Selector()
  static getSelectionFilter(state: AssessmentStateModel) {
    return state.selectedAsessmentFilter;
  }

  @Selector()
  static getError(state: AssessmentStateModel) {
    return state.error
  }

  @Action(SelectAssessmentUser)
  selectEntity(ctx: StateContext<AssessmentStateModel>, action: SelectAssessmentUser) {
    const state = ctx.getState();
    const selected = state.userList.find(entity => entity.id === action.id);
    ctx.patchState({
      selectedUser: selected
    });
  }


  @Action(SetSelectionFilter)
  setSelectionFilter(ctx: StateContext<AssessmentStateModel>, action: SetSelectionFilter) {
    ctx.patchState({
      selectedAsessmentFilter: action.selection
    });
  }

  @Action(ResetSelectionFilter)
  resetSelectionFilter(ctx: StateContext<AssessmentStateModel>, action: ResetSelectionFilter) {
    ctx.patchState({
      selectedAsessmentFilter: null
    });
  }

  @Action(FlagAssessment)
  flagAssessment(ctx: StateContext<AssessmentStateModel>, action: FlagAssessment) {
    ctx.patchState({ loading: LoadingAssessmentState.loadingAddUpdate });
    return this.assessmentService.flagAssessment(action.payload).pipe(
      tap((result: any) => {
        const state = ctx.getState();
        const index = state.userList.findIndex(entity => entity.assessment?.id === action.payload.assessmentId);
        let userList = [...state.userList]; 
        //update assessment for user
        userList[index].assessment = result;
        ctx.patchState({ userList, loading: LoadingAssessmentState.notLoading, error: null });
      }),
      catchError((error) => {
        ctx.patchState({
          loading: LoadingAssessmentState.notLoading,
          error: error.message
        });
        throw error;
      })
    )
  }

  @Action(FlagAssessmentUpdate)
  flagAssessmentUpdate(ctx: StateContext<AssessmentStateModel>, action: FlagAssessmentUpdate) {
    ctx.patchState({ loading: LoadingAssessmentState.loadingAddUpdate });
    return this.assessmentService.flagAssessmentUpdate(action.payload).pipe(
      tap((result: any) => {
        const state = ctx.getState();
        let userList = [...state.userList];
        const index = state.userList.findIndex(entity => entity.assessment?.id === result.assessmentId);
        let currentAssessmentUser: AssessmentUser  = state.userList.find(entity => entity.assessment?.id === result.assessmentId)!;
        let flagIndex = currentAssessmentUser.assessment?.flags?.findIndex(entity => entity.id === result.id);
        if (currentAssessmentUser.assessment && currentAssessmentUser.assessment.flags) {
          currentAssessmentUser.assessment.flags[flagIndex!] = result;
        }
        userList[index] = currentAssessmentUser!;
        ctx.patchState({ userList, loading: LoadingAssessmentState.notLoading, error: null });

        console.log('Response from Flag', result);
      }),
      catchError((error) => {
        ctx.patchState({
          loading: LoadingAssessmentState.notLoading,
          error: error.message
        });
        throw error;
      })
    )
  }

  @Action(ReleaseSingleResult)
  releaseSingleResult(ctx: StateContext<AssessmentStateModel>, action: ReleaseSingleResult) {
    ctx.patchState({ loading: LoadingAssessmentState.loadingAddUpdate });
    return this.assessmentService.relaseSingleResult(action.id).pipe(
      tap((result: any) => {
        const state = ctx.getState();
        console.log('Release Single', result);
        // ctx.patchState({
        //   assessment: [...state.assessment, result],
        //   loading: LoadingAssessmentState.notLoading
        // })
      }),
      catchError((error) => {
        ctx.patchState({
          loading: LoadingAssessmentState.notLoading,
          error: error.message
        });
        throw error;
      })
    )
  }

  @Action(ReleaseAllResults)
  releaseAllResults(ctx: StateContext<AssessmentStateModel>, action: ReleaseAllResults) {
    ctx.patchState({ loading: LoadingAssessmentState.loadingAddUpdate });
    return this.assessmentService.releaseAllResults().pipe(
      tap((result: any) => {
        const state = ctx.getState();
        console.log('Release All', result);
        // ctx.patchState({
        //   assessment: [...state.assessment, result],
        //   loading: LoadingAssessmentState.notLoading
        // })
      }),
      catchError((error) => {
        ctx.patchState({
          loading: LoadingAssessmentState.notLoading,
          error: error.message
        });
        throw error;
      })
    )
  }

  @Action(AddAssessment)
  addAssessment(ctx: StateContext<AssessmentStateModel>, action: AddAssessment) {
    ctx.patchState({ loading: LoadingAssessmentState.loadingAddUpdate });
    return this.assessmentService.addAssessment(action.payload).pipe(
      tap((result: any) => {
        const state = ctx.getState();
        ctx.patchState({
          assessment: [...state.assessment, result],
          loading: LoadingAssessmentState.notLoading
        })
      }),
      catchError((error) => {
        ctx.patchState({
          loading: LoadingAssessmentState.notLoading,
          error: error.message
        });
        throw error;
      })
    )
  }

  @Action(GetAssessment)
  getAssessment(ctx: StateContext<AssessmentStateModel>, action: GetAssessment) {
    ctx.patchState({ loading: LoadingAssessmentState.loadingList });
    return this.assessmentService.getAssessment(action.id).pipe(
      tap((result: any) => {
        const state = ctx.getState();
        ctx.patchState({
          assessment: result.data,
          loading: LoadingAssessmentState.notLoading
        })
      }),
      catchError((error) => {
        ctx.patchState({
          loading: LoadingAssessmentState.notLoading,
          error: error.message
        });
        throw error;
      })
    )
  }


  @Action(ListAssessmentUsers)
  getAssessmentList(ctx: StateContext<AssessmentStateModel>, action: ListAssessmentUsers) {
    ctx.patchState({ loading: LoadingAssessmentState.loadingList });
    return this.assessmentService.getAssessmentList(action.payload).pipe(
      tap((result: any) => {
        const state = ctx.getState();
        ctx.patchState({
          userList: result,
          loading: LoadingAssessmentState.notLoading
        })
      }),
      catchError((error) => {
        ctx.patchState({
          loading: LoadingAssessmentState.notLoading,
          error: error.message
        });
        throw error;
      })
    )
  }

  @Action(GetAssessmentByWeeklySchedule)
  getAssessmentByWeeklySchedule(ctx: StateContext<AssessmentStateModel>, action: GetAssessmentByWeeklySchedule) {
    ctx.patchState({ loading: LoadingAssessmentState.loadingList });
    return this.assessmentService.getAssessmentByWeeklySchedule(action.weeklyScheduleId).pipe(
      tap((result: any) => {
        const state = ctx.getState();
        ctx.patchState({
          userList: result,
          loading: LoadingAssessmentState.notLoading
        })
      }),
      catchError((error) => {
        ctx.patchState({
          loading: LoadingAssessmentState.notLoading,
          error: error.message
        });
        throw error;
      })
    )
  }

  @Action(SetLoading)
  setLoading(ctx: StateContext<AssessmentStateModel>, action: SetLoading) {
    ctx.patchState({ loading: action.loading });
  }

  @Action(SetError)
  setError(ctx: StateContext<AssessmentStateModel>, action: SetError) {
    ctx.patchState({ error: action.error });
  }

}