import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { LearningType } from '@tsin-core/entities';
import {
  AssignFacilitatorRequest,
  FacilitatorModel,
  FacilitatorRequest,
} from '@tsin-core/models/facilitator.model';
import { environment } from '@tsin-env/environment';
import { map, Observable, shareReplay } from 'rxjs';

const USER_URL = `${environment.userBaseUrl}`;
const GUESTS_API_URL = `${environment.userBaseUrl}/guest`;
const FACILITATOR_URL = `${environment.learningBaseUrl}/facilitators`;
const LEARNING_URL = `${environment.learningBaseUrl}`;


@Injectable({
  providedIn: 'root',
})
export class UserService {
  // inject the http client into the user service
  http: HttpClient = inject(HttpClient);

  // Method for getting the list of facilitators
  getFacilitatorUsers() {
    return this.http.get(FACILITATOR_URL);
  }


  getFacilitatorById(payload: string): Observable<FacilitatorModel> {
    return this.http.get<FacilitatorModel>(FACILITATOR_URL + '/single/userId/' + payload);
  }

  // Method for creating a new facilitator
  createFacilitatorUser(payload: FacilitatorRequest) {
    return this.http.post(FACILITATOR_URL, payload);
  }

  // Method for updating an existing facilitator
  updateFacilitatorUser(payload: FacilitatorRequest) {
    return this.http.put(FACILITATOR_URL, payload);
  }

  updateFacilitatorEmail(payload: any) {
    return this.http.post(FACILITATOR_URL + '/change-email', payload);
  }

  // Method for assigning facilitator
  assignFacilitatorUser(payload: Omit<AssignFacilitatorRequest, 'id'>) {
    return this.http.post(FACILITATOR_URL + '/assign-facilitator', payload);
  }

  unAssignFacilitatorUser(payload: Omit<AssignFacilitatorRequest, 'id'>) {
    return this.http.post<any[]>(FACILITATOR_URL + '/unassign-facilitator', payload);
  }

  getFacilitatorsSchedules(weeklyScheduleId: string) {
    return this.http.get(LEARNING_URL + `/schedules/slim/${weeklyScheduleId}`);
  }

  // Method for deleting an existing facilitator from the server
  deleteFacilitatorUser(payload: string) {
    return this.http.delete(FACILITATOR_URL + `/single/${payload}`);
  }

  public getLearningGuests(learningType?: LearningType) {
    const params: { type: string, programType: LearningType } | { type: string } = learningType ? { type: 'learning', programType: learningType } : { type: 'learning' };

    return this.http.get<{ guest: Array<any> }>(GUESTS_API_URL, { params: params })
      .pipe(
        map(res => res.guest),
        map((allGuest) => allGuest.map(guest => ({ _id: guest._id, user: guest }))), shareReplay(1),
      )
  }

}
